import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';

const Upload = () => {
	const [file, setFile] = useState(null)
	const [statustext, setStatustext] = useState("kakka")
	const navigate = useNavigate();
	const signOut = () => {
		localStorage.removeItem('cairocodersToken')
		navigate("/");
	}

	const onFileChange = (event) => {
		setFile(event.target.files[0])
		console.log("file onfilechangessa", file)

	};
	const handleFileUpload = async () => {

		//console.log("file handlessa", file)
		const token = "Bearer " + localStorage.getItem('cairocodersToken')

		const formData = new FormData();
		formData.append("file", file);

		axios
			.post(process.env.REACT_APP_API_BASEURL + "/api/upload", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					'Authorization': token
				},
			})
			.then((response) => {
				console.log("postin jälkeinen then")
				// handle the response
				//console.log(response.status);
				if (response.status === 200) {
					console.log("UPPI OK!")
					console.log(response.status)
					setStatustext("File uploaded sakksesfuli!")
					console.log(statustext)
					file.current.value = null;

				}
			})

			.catch((error) => {
				// handle errors
				console.log(error);
			});
	};

	return (
		<>
			<p><Link to="/profile" className="btn btn-success">Profile</Link> | <Link to="/upload" className="btn btn-success">Upload</Link> | <button type='button' className="btn btn-success" onClick={signOut}>Sign Out</button></p>
			<div style={{ minHeight: 800, marginTop: 20 }}>
				<h1>Upload</h1>
				<p>File upload would be here</p>
				<input type="file" onChange={onFileChange} />
				<button onClick={handleFileUpload}>
					Upload!
				</button>
				<p>{statustext}</p>
			</div>
			<div>
				<button type='button' className="btn btn-success btn-lg" onClick={signOut}>Sign Out</button>
			</div>
		</>
	)
}
export default Upload