import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';

//TODO: Add MAP filtering for entries and remove successfully removed item from it!
//Also make backends upload list response better (give very item a name)

const Profile = () => {
	const navigate = useNavigate();
	const [user_email, setEmail] = useState("")
	const [user_uploads, setUserUploads] = useState("")


	const signOut = () => {
		localStorage.removeItem('cairocodersToken')
		navigate("/");
	}
	useEffect(() => {
		const token = "Bearer " + localStorage.getItem('cairocodersToken')
		axios
			.get(process.env.REACT_APP_API_BASEURL + '/me', {
				headers: {
					'Accept': 'application/json',
					'Authorization': token
				}
			})
			.then(response => {
				setEmail(response.data.email)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])

	useEffect(() => {
		const token = "Bearer " + localStorage.getItem('cairocodersToken')
		axios
			.get(process.env.REACT_APP_API_BASEURL + '/uploads', {
				headers: {
					'Accept': 'application/json',
					'Authorization': token
				}
			})
			.then(response => {
				setUserUploads(response.data.uploads)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])
	const deleteFile = (fileid) => {
		console.log("Fileid", fileid)
		const token = "Bearer " + localStorage.getItem('cairocodersToken')
		axios({
			method: 'DELETE',
			url: process.env.REACT_APP_API_BASEURL + '/uploads/delete/' + fileid,
			headers: {
				'Accept': 'application/json',
				'Authorization': token
			},
		})
			.then(function (response) {
				console.log(response);
				console.log(response["status"])
				//console.log(response.data);
				//alert(response.data["message"])
				if (response.data["message"] === "Login failed") {
					alert("Login failed");
				}
				else {
					if (response["status"] === 200) {
						console.log("Tiedosto poistettu!")
						setUserUploads(user_uploads.filter((upload) => upload.table_name !== fileid))

					}
				}
			})
	}

	return (
		<>
			<div style={{ minHeight: 800, marginTop: 20 }}>
				<p><Link to="/profile" className="btn btn-success">Profile</Link> | <Link to="/upload" className="btn btn-success">Upload</Link>| <button type='button' className="btn btn-success" onClick={signOut}>Sign Out</button>  </p>

				<h1>Profile Page</h1>
				<p>Hi and welcome {user_email}</p>
				<p>Your uploads:</p>

				<ul>
					<table>
						<tbody>
							{user_uploads.length ?
								<tr>
									<th>Filename</th>
									<th>Secret</th>
								</tr>
								: null}
							{user_uploads.length ? user_uploads.map((upload, i) =>
								<tr key={i}>
									<td>{upload.name}</td>
									<td>{upload.table_name}</td>
									<td><input value={process.env.REACT_APP_BACKEND_BASEURL + "/" + upload[1]} disabled readOnly></input></td>
									<td><button onClick={() => deleteFile(upload.table_name)}>Delete</button></td>
								</tr>

							) : <p>No uploads yet!</p>}
						</tbody>
					</table>
				</ul>
			</div>

		</>
	)
}
export default Profile